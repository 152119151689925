const vars = {
  colors: {
    bodyColor: '#000000',
    text: '#ffffff',
    textDark: '#222222',
    textLight: '#adb5bd',
    red: '#e93d3d',
    orange: '#ff6f00',
    yellow: '#ffbc00',
    green: '#37ca37',
    blue: '#188bf6',
    teal: '#17cfbc',
    pink: '#ff3e7f',
    purple: 'rebeccapurple',
  },
  fonts: {
    fontSize: '1.25rem',
    fontFamily: 'TT Commons, Roboto, system, -apple-system, BlinkMacSystemFont, ".SFNSDisplay-Regular", "Helvetica Neue", Helvetica, Arial, sans-serif',
    fontWeight: 400,
    lineHeight: 1.8,
  },
  media: {
    smMin: '576px',
    mdMin: '768px',
    lgMin: '992px',
    xlMin: '1200px',
    xxlMin: '1400px',
    xsMax: '575px',
    smMax: '767px',
    mdMax: '991px',
    lgMax: '1199px',
    xlMax: '1399px',
  },
  transitions: {
    hover1: 'all 0.2s ease-in-out 0s', 
    hover2: 'color 0.2s ease-in-out 0s, background-color 0.2s ease-in-out 0s, border 0.2s ease-in-out 0s, opacity 0.2s ease-in-out 0s', 
    hover3: 'all 1s cubic-bezier(.77,0,.175,1) 0s',
    hover4: 'transform 1s cubic-bezier(.77,0,.175,1) 0s',
    hover5: 'all 0.5s ease-in-out 0s',
  },
  maxWidth: '1540px',
  navBreakpoint: 768,
  headerHeight: 80,
  headerHeightSm: 60,
}

export default vars;