import React from 'react';
//style
import styled, { createGlobalStyle } from 'styled-components';
import reset from 'components/styles/reset';
import typography from 'components/styles/typography';
import smooth from 'components/styles/smooth';
//components
import Header from 'components/ui/header';
import Nav from 'components/ui/nav';
//import SmoothScroll from 'components/ui/SmoothScroll';
//grid
import { GridThemeProvider } from 'styled-bootstrap-grid';

//Reset & Default Styles
const GlobalStyle = createGlobalStyle`
  ${reset};
  ${typography};
  ${smooth};
`;

//styled
const LayoutWrap = styled.div`
  position: relative;
  overflow: hidden;
`;

//styled
const Main = styled.main`
  position: relative;
  min-height: 100vh;
`;

//grid
const gridTheme = {
  row: {
    padding: 15,
  },
  col: {
    padding: 15,
  },
};

const Layout = ({ children }) => {

  return (
    <LayoutWrap>
      <GridThemeProvider gridTheme={gridTheme}>
        <GlobalStyle />
        <Header />
        <Nav />
        <Main>
          {children}
        </Main>
      </GridThemeProvider>
    </LayoutWrap>
  );
};

export default Layout;