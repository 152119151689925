import { css, createGlobalStyle } from 'styled-components'
import { darken, rem } from 'polished';
import vars from 'components/styles/varss';

//Web Fonts
import TTCommonsLight1 from 'assets/fonts/TTCommons-Light.ttf';
import TTCommonsLight2 from 'assets/fonts/TTCommons-Light.woff';
import TTCommonsLight3 from 'assets/fonts/TTCommons-Light.woff2';
import TTCommonsRegular1 from 'assets/fonts/TTCommons-Regular.ttf';
import TTCommonsRegular2 from 'assets/fonts/TTCommons-Regular.woff';
import TTCommonsRegular3 from 'assets/fonts/TTCommons-Regular.woff2';
import TTCommonsMedium1 from 'assets/fonts/TTCommons-Medium.ttf';
import TTCommonsMedium2 from 'assets/fonts/TTCommons-Medium.woff';
import TTCommonsMedium3 from 'assets/fonts/TTCommons-Medium.woff2';

const typography = css`
  /* My Typography Styles */
  body {
    background-color: ${vars.colors.bodyColor};
    font-size: ${vars.fonts.fontSize};
    font-family: ${vars.fonts.fontFamily};
    font-weight: ${vars.fonts.fontWeight};
    line-height:  ${vars.fonts.lineHeight};
    color: ${vars.colors.text};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  a,
  button {
    transition: ${vars.transitions.hover2};
    cursor: pointer;
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      outline: none;
    }
    &:active {
      &:focus {
        outline: none;
      }
    }
    &[x-apple-data-detectors] {
      color: inherit !important;
      text-decoration: none !important;
      font-size: inherit !important;
      font-family: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
    }
  }
  a {
    color: ${vars.colors.blue};
    &:hover,
    &:active,
    &:focus {
      color: ${darken(0.2, vars.colors.blue)};
    }
  }
  p {
    margin-bottom: 0;
    + p {
      margin-top: ${rem('20px')};
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${vars.colors.text};
    line-height: 1.2;
    margin-top: 0;
    margin-bottom: ${rem('20px')};
  }
  h1 {
    font-size: 2.5rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  h5 {
    font-size: 1.25rem;
  }
  h6 {
    font-size: 1rem;
  }
  /* TT Commons */
  @font-face {
    font-family: 'TT Commons';
    src: url(${TTCommonsRegular3}) format('woff2'),
         url(${TTCommonsRegular2}) format('woff'),
         url(${TTCommonsRegular1}) format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'TT Commons';
    src: url(${TTCommonsLight3}) format('woff2'),
         url(${TTCommonsLight2}) format('woff'),
         url(${TTCommonsLight1}) format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: fallback;
  }
  @font-face {
    font-family: 'TT Commons';
    src: url(${TTCommonsMedium3}) format('woff2'),
         url(${TTCommonsMedium2}) format('woff'),
         url(${TTCommonsMedium1}) format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: fallback;
  }
  .overflow {
    overflow: hidden;
  }
  .underline {
    height: 2px;
    background-color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    &.black {
      background-color: #000;
    }
  }
`;

export const Typography = createGlobalStyle`${typography}`;

export default typography;