import React, { useContext } from 'react';
//context
import { GlobalContext } from 'context/';
//styles
import styled, { css } from 'styled-components';
import { between, rem, rgba } from 'polished';
import vars from 'components/styles/varss';
//framer motion
import { motion } from 'framer-motion';
//grid
import { Row, Col } from 'styled-bootstrap-grid';

//styled
const NavWrap = styled.nav`
  position: fixed;
  margin: auto;
  top: 0;
  right: 0;
  z-index: 99;
  color: #333333;
  width: 100%;
  max-width: 1300px;
  pointer-events: none;
  transition: ${vars.transitions.hover3};
  ${props => props.active && css`
    pointer-events: auto;
  `}
  @media(max-width: ${vars.media.mdMax}) {
    overflow-y: auto;
    height: 100vh;
  }
  /* active */
  ${props => props.active && css`
    
    @media(max-width: ${vars.media.smMax}) {
      background-color: #fff;
    }
  `}
`;

const NavBG1 = styled(motion.span)`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 50%;
  bottom: 0;
  background-color: ${rgba('#ffffff', 0.98)};
  will-change: transition;
  display: none!important;
  @media(min-width: ${vars.media.mdMin}) {
    border-right: 1px solid #eee;
    display: block!important;
  }
`;

const NavBG2 = styled(motion.span)`
  position: absolute;
  margin: auto;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  background-color: ${rgba('#ffffff', 0.98)};
  will-change: transition;
  display: none!important;
  @media(min-width: ${vars.media.mdMin}) {
    display: block!important;
  }
`;

const NavInner = styled(motion.div)`
  position: relative;
  overflow-y: scroll;
  z-index: 2;
  will-change: opacity;
  padding: 150px 40px 140px 40px;
  @media(max-width: ${vars.media.smMax}) {
    padding: 80px 40px;
  }
`;

const NavInfos = styled(motion.div)`
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  color: #A7A7A7;
  font-size: ${rem('16px')};
  @media(max-width: ${vars.media.smMax}) {
    max-width: 100%;
  }
  a {
    font-size: ${rem('16px')};
    color: #333333;
    border-bottom: 1px solid transparent;
    &:hover {
      border-color: #333333;
    }
  }
  .nav-email {
    margin-bottom: ${rem('60px')};
    @media(max-width: ${vars.media.smMax}) {
      margin-bottom: ${rem('30px')};
    }
  }
  .nav-mailing-list {
    margin-bottom: ${rem('45px')};
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
      margin-right: 10px;
    }
    input {
      border: none;
      border-bottom: 1px solid #333333;
      background-color: transparent;
    }
  }
  .nav-social-links {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 80px;
    li {
      a {
        color: #A7A7A7;
        &:hover {
          color: #333333;
        }
      }
    }
  }
  .nav-locations {
    h4 {
      color: #333333;
      font-size: ${rem('16px')};
      font-weight: 500;
      margin-bottom: 30px;
    }
    p {
      line-height: 1.5;
    }
  }
`;

const NavList = styled(motion.ul)`
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  @media(max-width: ${vars.media.smMax}) {
    max-width: 100%;
    margin-bottom: ${rem('60px')};
  }
  li {
    a {
      color: #333333;
      font-size: ${rem('50px')};
      font-weight: 300;
      border-bottom: 1px solid transparent;
      @media (max-width: ${vars.media.lgMax}) {
        font-size: ${between('30px', '50px')};
      }
      &:hover {
        border-color: #333333;
      }
      &.active {
        border-color: #333333;
      }
    }
  }
`;

//animations
const TransitionY1 = {
  initial: {
    y: "-100%",
    transition: {
      ease: [.77, 0, .175, 1],
      duration: 1,
      delay: 0.2,
    }
  },
  animate: {
    y: "0",
    transition: {
      ease: [.77, 0, .175, 1],
      duration: 1,
      delay: 0.2,
    }
  }
};

const TransitionY2 = {
  initial: {
    y: "-100%",
    transition: {
      ease: [.77, 0, .175, 1],
      duration: 1,
    }
  },
  animate: {
    y: "0",
    transition: {
      ease: [.77, 0, .175, 1],
      duration: 1,
    }
  }
};

const Opacity = {
  initial: {
    opacity: 0,
    transition: {
      ease: [.77, 0, .175, 1],
      duration: 1,
    }
  },
  animate: {
    opacity: 1,
    transition: {
      ease: [.77,0,.175,1],
      duration: 1,
      delay: .5,
    }
  }
};

const AnimateNavItems = {
  animate: {
    transition: {
      delayChildren: 0.25,
      staggerChildren: 0.1,
    }
  }
};

const FadeUp = {
  initial: {
    y: '70px',
    opacity: 0,
    transition: {
      ease: [.38, .32, .36, .98],
      delay: 1,
    }
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      ease: [.38, .32, .36, .98],
      duration: 1.25,
    }
  }
};

const Nav = () => {

  //use Context
  const [context, setContext] = useContext(GlobalContext);

  //toggle mobile nav
  const closeNav = () => {
    setContext({
      ...context,
      nav: false
    });
  };

  return (
    <NavWrap active={context.nav}>
      <NavBG1
        variants={TransitionY1}
        initial={false}
        animate={context.nav ? 'animate' : 'initial'}/>
      <NavBG2
        variants={TransitionY2}
        initial={false}
        animate={context.nav ? 'animate' : 'initial'}/>
      <NavInner
        variants={Opacity}
        initial={false}
        animate={context.nav ? 'animate' : 'initial'}>
        <Row>
          <Col md={6} order={2} smOrder={2}>
            <NavInfos variants={AnimateNavItems}>
              <motion.p variants={FadeUp} className="nav-email">Get in touch: <a href="mailto:info@integral.im">info@integral.im</a></motion.p>
              <motion.div variants={FadeUp} className="nav-mailing-list">
                <label htmlFor="#mailing-list">Join our mailing list</label>
                <input type="text"/>
              </motion.div>
              <motion.ul variants={FadeUp} className="nav-social-links">
                <li><a href="#/">Instagram</a></li>
                <li><a href="#/">LinkedIn</a></li>
                <li><a href="#/">Instagram</a></li>
              </motion.ul>
              <motion.div variants={FadeUp}>
                <Row className="nav-locations">
                  <Col col={6}>
                    <h4>Hong Kong</h4>
                    <p>
                      610 Nathan Road,<br />
                    Kowloon, Hong Kong
                  </p>
                  </Col>
                  <Col col={6}>
                    <h4>Lisbon</h4>
                    <p>34 Rua Ferragial,<br />
                    1200-183 Lisbon
                  </p>
                  </Col>
                </Row>
              </motion.div>
            </NavInfos>
          </Col>
          <Col md={6} order={1} smOrder={1}>
            <NavList variants={AnimateNavItems} onClick={closeNav}>
              <motion.li variants={FadeUp}><a href="#/" className="active">Home</a></motion.li>
              <motion.li variants={FadeUp}><a href="#/">Work</a></motion.li>
              <motion.li variants={FadeUp}><a href="#/">Approach</a></motion.li>
              <motion.li variants={FadeUp}><a href="#/">Studio</a></motion.li>
              <motion.li variants={FadeUp}><a href="#/">Contact</a></motion.li>
            </NavList>
          </Col>
        </Row>
      </NavInner>
    </NavWrap>
  );
};

export default Nav;