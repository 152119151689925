//styles
import styled, { css } from 'styled-components';
import { between } from 'polished';
import vars from 'components/styles/varss';

//styled
const Heading = styled.h1`
  color: ${vars.colors.text};
  font-weight: 500;
  line-height: ${props => props.lh ? props.lh : 1};
  letter-spacing: -0.56px;
  margin-bottom: ${props => props.mb ? props.mb : '20px'};
  font-size: ${props => props.size ? props.size+'px' : '90px'};
  @media (max-width: ${vars.media.lgMax}) {
    font-size: ${props => props.size ? between((props.size - 10)+'px', props.size+'px') : between('40px', '90px')};
  }
  /* Heading2 */
  ${props => props.heading2 && css`
    font-size: ${props => props.size ? props.size+'px' : '60px'};
    font-weight: 500;
    line-height: 1.25;
    letter-spacing: normal;
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${props => props.size ? between((props.size - 8) + 'px', props.size + 'px') : between('30px', '60px')};
    }
  `}
  /* Heading2b */
  ${props => props.heading2b && css`
    font-size: ${props => props.size ? props.size + 'px' : '75px'};
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: normal;
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${props => props.size ? between((props.size - 8) + 'px', props.size + 'px') : between('45px', '75px')};
    }
  `}
  /* Heading3 */
  ${props => props.heading3 && css`
    font-size: ${props => props.size ? props.size + 'px' : '60px'};
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${props => props.size ? between((props.size - 8) + 'px', props.size + 'px') : between('40px', '60px')};
    }
  `}
  /* Heading4 */
  ${props => props.heading4 && css`
    font-size: ${props => props.size ? props.size + 'px' : '30px'};
    line-height: 1.33;
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${props => props.size ? between((props.size - 6) + 'px', props.size + 'px') : between('24px', '30px')};
    }
  `}
  /* Heading5 */
  ${props => props.heading5 && css`
    font-size: ${props => props.size ? props.size + 'px' : '20px'};
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${props => props.size ? between((props.size - 4) + 'px', props.size + 'px') : between('16px', '20px')};
    }
  `}
  /* Heading6 */
  ${props => props.heading6 && css`
    font-size: ${props => props.size ? props.size + 'px' : '16px'};
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${props => props.size ? props.size + 'px' : '16px'};
    }
  `}
  /* Underlined */
  ${props => props.underlined && css`
    display: inline-block;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    font-weight: normal;
    overflow: hidden;
    font-size: ${props => props.size ? props.size + 'px' : '30px'};
    padding-bottom: 12px;
    @media (max-width: ${vars.media.lgMax}) {
      font-size: ${props => props.size ? between((props.size - 8) + 'px', props.size + 'px') : between('24px', '30px')};
    }
  `}
`;

export default Heading;