import React, { useState, useContext } from 'react';
//gatsby
import { Link } from 'gatsby';
//context
import { GlobalContext } from 'context/';
//styles
import styled, { css } from 'styled-components';
import vars from 'components/styles/varss';
//components
import Container from 'components/ui/container';
import NavToggle from 'components/ui/navToggle';

//styled
const HeaderWrap = styled.header`
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: ${vars.transitions.hover5};
  transform: translateY(100px);
  will-change: transform;
  @media(max-width: ${vars.media.smMax}) {
    transform: translateY(40px);
  }
  ${Container} {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 60px;
    padding-right: 60px;
    @media(max-width: ${vars.media.smMax}) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .link {
    line-height: 1;
  }
  .logo {
    margin-right: 15px;
  }
`;

const Header = () => {

  //logo color
  const [logoColor] = useState('#1D1D1B');

  return (
    <HeaderWrap>
      <Container>
        <Link to="/" className="link">
          <svg width={69} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" className="logo">
            <path d="M1.873 15.777H0V0h1.873v15.777zM9.8 4.36a3.977 3.977 0 011.697.368c.524.243.996.584 1.39 1.005.415.445.738.967.952 1.537.233.6.35 1.263.35 1.99v6.518h-1.874V9.265a3.485 3.485 0 00-.227-1.28 3.17 3.17 0 00-.609-.997 2.59 2.59 0 00-1.942-.857c-.893 0-1.618.284-2.175.851-.558.567-.836 1.328-.836 2.283v6.512H4.654V4.482h1.383l.503 1.262c.244-.259.52-.485.822-.673a4.828 4.828 0 011.03-.496c.4-.143.87-.214 1.408-.214zM17.536 1.475h1.646v3.008h2.497v1.76h-2.497v5.876c0 .635.17 1.128.511 1.478.341.35.796.525 1.365.525a1.96 1.96 0 00.45-.048.54.54 0 00.171-.074v1.647a1.35 1.35 0 01-.268.122c-.224.082-.516.124-.875.124-.347 0-.691-.064-1.015-.189a3.318 3.318 0 01-1.238-.848 3.603 3.603 0 01-.714-1.172 4.262 4.262 0 01-.262-1.538V6.242h-1.984V4.483h.827c.932 0 1.393-.458 1.384-1.375l.002-1.633z" fill={logoColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M31.854 13.955c.276-.371.516-.769.714-1.187h-2.017c-.114.159-.24.309-.375.45-.169.172-.361.32-.572.437a5.159 5.159 0 01-.757.35c-.302.107-.62.159-.941.153a2.854 2.854 0 01-1.187-.264 3.488 3.488 0 01-1.021-.695 3.77 3.77 0 01-.726-1.009 3.206 3.206 0 01-.338-1.181h8.056a19.143 19.143 0 00.098-.584c.018-.141.026-.283.025-.425.002-.852-.13-1.627-.397-2.324a5.543 5.543 0 00-1.082-1.79 4.791 4.791 0 00-5.53-1.126 5.086 5.086 0 00-1.657 1.198 5.743 5.743 0 00-1.12 1.827c-.274.705-.412 1.484-.412 2.337 0 .862.137 1.645.412 2.35.257.676.641 1.296 1.132 1.827a5.11 5.11 0 003.746 1.618 4.77 4.77 0 001.63-.264 5.172 5.172 0 001.31-.69 4.714 4.714 0 001.01-1.008zm-5.167-7.602a2.542 2.542 0 011.096-.245c.75 0 1.405.29 1.964.874.559.583.904 1.377 1.034 2.38H24.65c.06-.454.19-.895.386-1.31a3.77 3.77 0 01.71-1.026c.271-.279.59-.507.942-.673zM41.723 14.625c.296-.214.56-.47.783-.758l.001 1.502c0 .823-.284 1.51-.85 2.06-.568.549-1.328.824-2.283.824-.824 0-1.445-.164-1.861-.49a2.914 2.914 0 01-.906-1.135h-1.875c.114.464.305.906.564 1.308a4.342 4.342 0 002.32 1.802c.518.175 1.103.262 1.756.262.751 0 1.435-.116 2.05-.348a4.576 4.576 0 001.58-.977 4.437 4.437 0 001.016-1.479 4.62 4.62 0 00.363-1.826V4.484H43.01l-.502 1.259a4.245 4.245 0 00-.783-.672 3.969 3.969 0 00-1.004-.495c-.408-.142-.898-.213-1.469-.213a5.067 5.067 0 00-2.026.401 4.935 4.935 0 00-1.628 1.124A5.217 5.217 0 0034.51 7.63a6.066 6.066 0 00-.402 2.242c0 .815.134 1.56.402 2.236a5.3 5.3 0 001.09 1.741 4.8 4.8 0 001.633 1.124 5.146 5.146 0 002.018.399c.563 0 1.049-.078 1.457-.233.358-.129.7-.301 1.016-.513zm-5.456-3.197a4.274 4.274 0 01-.263-1.542c0-.571.088-1.087.265-1.548a3.364 3.364 0 011.811-1.934 3.196 3.196 0 011.317-.276c.45-.003.897.091 1.31.276a3.38 3.38 0 011.805 1.934c.181.495.27 1.02.263 1.548a4.29 4.29 0 01-.263 1.536 3.395 3.395 0 01-1.805 1.946 3.28 3.28 0 01-2.635 0 3.393 3.393 0 01-1.077-.765 3.507 3.507 0 01-.728-1.175z" fill={logoColor} />
            <path d="M52.438 6.242h-.54c-.884 0-1.601.284-2.152.852-.552.568-.827 1.33-.827 2.285v6.398h-1.873V4.482h1.384l.502 1.17c.246-.243.521-.453.82-.626a4.993 4.993 0 011.027-.46c.4-.132.868-.198 1.407-.198h.25l.002 1.874z" fill={logoColor} />
            <path fillRule="evenodd" clipRule="evenodd" d="M60.886 15.223c.31-.195.594-.429.845-.696l.501 1.251h1.384V4.483h-1.384l-.5 1.26a5.025 5.025 0 00-.846-.673 4.696 4.696 0 00-1.053-.496c-.417-.143-.911-.215-1.483-.215a4.994 4.994 0 00-2.045.429c-.637.28-1.21.686-1.684 1.194a5.606 5.606 0 00-1.127 1.819c-.274.702-.41 1.478-.41 2.327 0 .857.136 1.637.41 2.34.255.672.638 1.29 1.127 1.818a5.09 5.09 0 003.73 1.61c.563 0 1.055-.065 1.475-.195.374-.11.73-.27 1.06-.478zm.83-6.732c.192.49.288 1.036.288 1.64.007.562-.09 1.12-.288 1.646a4.041 4.041 0 01-.765 1.267 3.338 3.338 0 01-1.12.814 3.267 3.267 0 01-2.679 0 3.338 3.338 0 01-1.12-.814 4.037 4.037 0 01-.765-1.267 4.509 4.509 0 01-.288-1.646c0-.604.096-1.15.288-1.64.176-.464.435-.891.765-1.261a3.339 3.339 0 011.12-.814 3.267 3.267 0 012.679 0c.426.19.807.467 1.12.814.33.37.59.797.765 1.26z" fill={logoColor} />
            <path d="M68.214 15.777H66.34V0h1.875v15.777z" fill={logoColor} />
          </svg>
        </Link>
        <NavToggle />
      </Container>
    </HeaderWrap>
  );
};

export default Header;