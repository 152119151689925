import React, { useContext } from 'react';
//context
import { GlobalContext } from 'context';
//styles
import styled, { css } from 'styled-components';
import vars from 'components/styles/varss';
//framer motion
import { motion } from 'framer-motion';

const NavToggleWrap = styled.div`
  border: none;
  position: relative;
  padding: 0;
  line-height: 1;
  width: 24px;
  height: 24px;
  background: transparent;
  cursor: pointer;
  mix-blend-mode: difference;
`;

const ToggleLine = styled(motion.span)`
  display: block;
  width: 24px;
  height: 1px;
  background-color: #fff;
  will-change: background-color, transtion, mix-blend-mode;
  transition: ${vars.transitions.hover3};
  position: absolute;
  transform-origin: center;
  transition: all 200ms ease;
  mix-blend-mode: difference;
  /* top */
  ${props => props.top && css`
    margin: auto;
    top: 8px;
    left: 0;
    right: 0;
  `}
  /* bottom */
  ${props => props.bottom && css`
    margin: auto;
    left: 0;
    right: 0;
    bottom: 8px;
  `}
  /* active */
  ${props => props.active && css`
    background-color: #000;
  `}
  /* color dark */
  ${props => props.dark && css`
    background-color: #000;
  `}
  /* top active */
  ${props => props.top && props.active && css`
    transform: rotate(-45deg) translateX(-3px) translateY(2px);
  `}
  /* bottom active */
  ${props => props.bottom && props.active && css`
    transform: rotate(45deg) translateX(-3px) translateY(-2px);
  `}
`;

const NavToggle = () => {

  //use Context
  const [context, setContext] = useContext(GlobalContext);

  //toggle nav
  const toggleNav = () => {
    setContext({
      ...context,
      nav: !context.nav
    });
  };

  return (
    <NavToggleWrap onClick={toggleNav}>
      <ToggleLine top active={context.nav} dark={context.navDark} />
      <ToggleLine bottom active={context.nav} dark={context.navDark} />
    </NavToggleWrap>
  );
};

export default NavToggle;